.template2 > .swiper-slide{
    height: 200px;
    width: calc(25%);
    margin-right: 20px;
}

.template2 > .swiper-wrapper{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

@media screen and (max-width: 1535px) {
    .template2 > .swiper-wrapper{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1170px) {
    .template2 > .swiper-wrapper{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .template2 > .swiper-wrapper{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.template2, .swiper{
    width: 100%;
}

.template2 > .swiper-div{
    /* width: 100%;
    max-width: 320px;
    height: 100%;
    max-height: 390px; */
}