/* map.css */
.map-container {
    height: 90vh;
    width: 100%;
    position: relative;
  }
  
  .map-container .map {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 1024px) {
    .map-container {
        height: 45vh;
        width: 100%;
        margin-left: 0;
        position: relative;
      }
  }