.container{
    padding: 20px 0 150px 0;
    background-color: #F0E9E3;
    mix-blend-mode: multiply;
}

.btn-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.btn-events{
    border: 1px solid #DF5B23;
    transition: all .3s cubic-bezier(.215, .61, .355, 1);
}

.btn-events:hover, .btn-corsi:hover{
    cursor: pointer;
}

.btn-corsi{
    border: 1px solid #DF5B23;
    transition: all .3s cubic-bezier(.215, .61, .355, 1);
}

.evento{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    border-bottom: 1px solid black;
    padding: 0;
    margin-left: 2.5rem;
    margin-bottom: 30px;
    color: #796557;
    height: 90px;
    align-items: flex-end;
}

.evento > *{
    width: 15%;
}

.img-evento{
    width: 9rem;
    max-width: 176px;
    border-top: 10px;
}

.img-evento img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: fit-content;
}


.prezzo-container, .btn-cancella, .btn-pagamento, .btn-salda{
    height: 50px;
    min-width: 176px;
    max-width: 300px;
    margin: 0 10px;
    border-bottom: 0 !important;
}

.prezzo-container{
    background-color: white;
    white-space: nowrap;
    text-align: center;
}

.prezzo{
    text-align: start;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 164px;
}

.btn-cancella{
    border: 1px solid #857264;
}

.btn-pagamento{
    background-color: #DF5B23;
    border: 1px solid #DF5B23;
    color: white;
}

.btn-salda{
    background-color: #796557;
    border: 1px solid #796557;
    color: white;
}

.acquistato{
    transform: translateY(-25%);
    /* width: 54%; */
    text-align: end;
}


/* */
.hidden{
    display: none;
}