/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f1ed;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8a19a;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
