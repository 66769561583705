.hidden{
    display: none;
}

.lista-filtri.in-lista-filtri{
    transition: all 1s;
    animation: my-in 0.3s 1 cubic-bezier(0, 0, 0.54, 1.06);
    display: block;
}

.lista-filtri.out-lista-filtri{
    transition: all 1s;
    animation: my-out 0.3s 1 cubic-bezier(0, 0, 0.54, 1.06);
    display: block;
    transform: translateX(-100%);
}

@keyframes my-out {
    0% {transform: translateX(0); display: block;}
    90%{transform: translateX(-100%);}
    100%{display: block;}
}

@keyframes my-in {
    0% {display: block; transform: translateX(-100%);}
    /* 100%{transform: translateX(0);} */
}

.h-full-calc{
    height: calc(100vh - 120px);
}
