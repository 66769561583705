.main-container{
    
    mix-blend-mode: multiply;
    position: relative;
    height: 90vh;
    border-bottom: 140px solid  #F0E9E3;
}

.title{
    position: absolute;
    top: -90px;
    left: 0px;
}

.container-tessera{
    width: 100%;
    height: 100%;
    background-color: #E9DFD7;
}

.tessera{
    max-width: 484px;
    max-height: 301px;
    width: 100vw;
    height: 72vw;
    border-radius: 20px;
    box-shadow: 2px 4px 24px #7965573B;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tessera > img{
    position: absolute;
    mix-blend-mode: hard-light;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn-scarica{
    position: relative;
    bottom: -19px;
    left: 0px;
}

.container-rinnova{
    margin-left: 90px;
    margin-bottom: 140px;
    margin-top: 30px;
}

.btn-rinnova{
    display: block;
    margin-top: 30px;
}