.treks-container{
display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: start;
  margin-bottom: 150px;
  background-color: #F0E9E3;
  mix-blend-mode: multiply;
}

.treks-container > div {
    flex: 1 0 50;
    margin: 26px;
  }