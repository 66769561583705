.trek ol {
    display: block !important;
    list-style-type: decimal !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 40px !important;
    line-height: 1 !important;
}

.prova ol{
    list-style-type: decimal !important;
    padding-left: 40px !important;
}

.prova ul{
    list-style-type: disc !important;
    padding-left: 40px !important;
}

.trek ul {
    display: block !important;
    list-style-type: disc !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 40px !important;
    line-height: 1 !important;
}

.trek li, .trek li > * {
    line-height: 0.75rem !important;
}

.trek h1, .trek h1 > *,
.trek h2, .trek h2 > *,
.trek h3, .trek h3 > *,
.trek h4, .trek h4 > *,
.trek h5, .trek h5 > *,
.trek h6, .trek h6 > *,
.trek p, .trek p > * {
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    /* line-height: 1rem !important !important;  */
}

.trek strong *{
    font-weight: 700;
}

.prova, .prova *{
    font-size: 1.5rem!important;
}

/*mobile*/

.trek h6, .trek h6 > * {
    font-size: 0.625em  !important;
    /* line-height: 1 !important; */
}

.trek h5, .trek h5 > * {
    font-size: 0.750em  !important;
    /* line-height: 1 !important; */
}

.trek h4, .trek h4 > * {
    font-size: 0.875em  !important;
    /* line-height: 1 !important; */
}
.trek h3,
.trek p, .trek p > *, .trek h3 > * {
    font-size: 1em  !important;
    /* line-height: 1 !important; */
}

.trek h2, .trek h2 > * {
    font-size: 1.125em  !important;
    /* line-height: 1 !important; */
}

.trek h1, .trek h1 > * {
    font-size: 1.25em !important;
    /* line-height: 1 !important; */
}